<template>
  <div>
    <div
      v-loading="loading"
      element-loading-text="查询中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="fill-information-container"
    >
      <el-form label-width="130px" :rules="rules" :model="qszmFormData" ref="mainForm">
        <el-row type="flex" justify="center" :gutter="20">
          <el-col :span="12">
            <el-form-item label="姓名：" prop="qlrmc">
              <el-input @input="resultFlag = false" v-model="qszmFormData.qlrmc" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="证件类型：" prop="zjzl">
              <dict-select
                @change="resultFlag = false"
                v-model="qszmFormData.zjzl"
                type="证件种类"
                :disabled="disabled"
              ></dict-select>
            </el-form-item>
            <el-form-item label="证件号码：" prop="zjh">
              <el-input @input="resultFlag = false" v-model="qszmFormData.zjh" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="查档用途：" prop="cxyt">
              <dict-select @change="resultFlag = false" v-model="qszmFormData.cxyt" type="查档用途"></dict-select>
            </el-form-item>
            <el-form-item label="验证码：" prop="authCode">
              <div class="flex">
                <div style="width: 35%">
                  <el-input placeholder="验证码" v-model="qszmFormData.authCode"></el-input>
                </div>
                <div class="pointer code">
                  <div class="pointer code" @click="refreshCode">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                  </div>
                </div>
                <div @click="refreshCode" class="pointer link" style="line-height: 40px">换一张</div>
              </div>
            </el-form-item>

            <!-- <el-form-item label="手机号：" prop="tel">
              <el-input v-model="qszmFormData.tel"></el-input>
            </el-form-item>
            <el-form-item label="手机验证码" prop="authCode">
              <phone-code v-model="qszmFormData.authCode" :phone="qszmFormData.tel" :disabled="getCodeBtnDisabled"></phone-code>
            </el-form-item> -->
          </el-col>
          <!-- <el-col :span="12" v-if="$hasPermission('IEBDC:SSYTJ') && !flag">
            <el-button class="read-id-card-button" type="primary" @click="getIdCardInfo">读取身份证信息</el-button>
            <div class="lc-name">
              <span
                >所选一体机编号为: {{ chooseDeviceInfo.deviceBh ? chooseDeviceInfo.deviceBh : chooseDeviceInfo.deviceIp }}</span
              >
            </div>
            <div class="rz_img">
              <img class="rz_img_id" :src="qszmFormData.idImage" style="width: 164px" />
              <img class="rz_img_face" :src="qszmFormData.faceImage" style="width: 164px" />
              <div class="re_img_gayz" v-show="qszmFormData.gayz">
                <img :src="qszmFormData.gayz_img" />
                <span>{{ qszmFormData.gayz }}</span>
              </div>
            </div>
          </el-col> -->
        </el-row>
      </el-form>
      <div class="text-center">
        <el-button type="primary" @click="submit" :loading="loading">查询</el-button>
      </div>
    </div>
    <query-result
      v-show="resultFlag"
      :checkData="resultNumber"
      :cxmd="qszmFormData.cxyt"
      :totalNum="totalNum"
      :nCurrent="qszmFormData.nCurrent"
      :qszmFormData="qszmFormData"
      :key="index"
      @getListData="getListData"
      :cxjgData="cxjgData"
    />
    <!-- <query-result v-if="resultFlag" :checkData="resultNumber" :cdyt="qszmFormData.cxyt" /> -->
    <!--    <house-query-bank v-if="user.userCategory === 'FINANCIAL'"></house-query-bank>-->
    <!-- <e-dialog :config="dialogConfig"
              class="custom-class">
      <template slot="content">
        <iframe :src="printQszmUrl"
                class="full-width"
                height="800"></iframe>
      </template>
    </e-dialog> -->
  </div>
</template>

<script>
import { zxTemplate } from '@public/zsMould';
import hasPermission from '@iebdc/mixin/hasPermission';
import { localDataUser } from '@iebdc/utils/local-data';
import { mapGetters } from 'vuex';
import { bsdtDetail, getNewFwCdzm, checkCertifyInspect } from '@iebdc/api/qszmcx-service';
import SIdentify from '@/pages/iebdc/views/login/component/identify';
import { getCookie } from '@iebdc/utils/cookies';
// import { getInfoByDeviceBh } from '@iebdc/api/user-center/device.js';
// import { getDeviceList } from '@iebdc/api/user-center/device.js';
import HouseQueryBank from './house-query-bank';
import QueryResult from './query-result';
export default {
  name: 'fillInformation',
  mixins: [hasPermission],
  props: {
    ipData: {
      type: JSON,
      default: {},
    },
  },
  data() {
    const validatePhone = async (rule, value, callback) => {
      const re = /^((0\d{2,3}-\d{7,8})|((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8})$/g;
      if (!value) {
        this.getCodeBtnDisabled = true;
        return callback(new Error('请输入电话号码'));
      }
      if (!re.test(value)) {
        this.getCodeBtnDisabled = true;
        return callback(new Error('请输入合法的电话号码'));
      }
      this.getCodeBtnDisabled = false;
      callback();
    };

    // 验证码自定义验证规则
    const validateVerifycode = (rule, value, callback) => {
      let newVal = value.toLowerCase();
      let identifyStr = this.identifyCode.toLowerCase();
      if (newVal === '') {
        callback(new Error('请输入验证码'));
      } else if (newVal !== identifyStr) {
        callback(new Error('验证码不正确!'));
      } else {
        callback();
      }
    };

    const validateIDcard = (rule, value, callback) => {
      const zjh = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!value) {
        return callback(new Error('请输入证件号码'));
      } else if (!zjh.test(value)) {
        return callback(new Error('请输入正确的证件号码'));
      } else {
        callback();
      }
    };

    return {
      rules: {
        tel: [{ required: true, validator: validatePhone, trigger: 'change' }],
        qlrmc: [{ required: true, message: '请输入权利人名称', trigger: 'blur' }],
        zjh: [{ required: true, validator: validateIDcard, trigger: 'blur' }],
        zjzl: [{ required: true, message: '请选择证件种类', trigger: 'change ' }],
        cxyt: [{ required: true, message: '请选择查档用途', trigger: 'change' }],
        authCode: [{ required: true, validator: validateVerifycode, trigger: 'change' }],
      },
      bdcqzh: [],
      tempalteList: zxTemplate,
      totalNum: 0,
      qszmFormData: {
        qqjgmc: this.selectedOrganizationsTitle,
        bdcqzh: '', // 不动产权证号
        qydm: getCookie('selectedOrganizationsCode'), // 区域代码
        qlrmc: '', // 权利人名称
        zjzl: '', // 证件种类(身份证)
        zjh: '', // 证件号(一般是身份证号)
        loginName: '',
        // idImage: require('../../../assets/images/qszm/idimg.png'),
        // faceImage: require('../../../assets/images/qszm/faceimg.png'),
        cxyt: '', // 查档用途
        authCode: '', // 验证码
        tempalteList: zxTemplate,
        sqrqydm: '',
        sqrip: '',
        sqrszd: '',
        bdclx: 'FW',
        cdlx: '本人',
        cxlx: 'BRCX',
        nCurrent: 1,
        nSize: 10,
      },
      identifyCodes: '1234567890ABCDEFGHIGKLMNoPQRSTUVWXYZ',
      getCodeBtnDisabled: true,
      identifyCode: '',
      loading: false,
      chooseDeviceInfo: {},
      user: localDataUser.get(),
      resultFlag: false,
      dialogConfig: {
        // 弹框配置
        title: '房产查询详情',
        visible: false,
        width: '60%',
        hasConfirm: false,
        hasCancel: false,
        hasBtn: false,
      },
      printQszmUrl: '', //权属表地址
      resultNumber: {},
      index: 0,
      cxjgData: null,
    };
  },
  components: {
    HouseQueryBank,
    QueryResult,
    SIdentify,
  },
  computed: {
    ...mapGetters([
      'selectedOrganizationsTitle',
      'selectedAreaTitle',
      'selectedBsdtTitle',
      'selectedBsdtCode',
      'selectedOrganizationsCode',
    ]),
    disabled() {
      const { user } = this;
      return user.qylx !== '2';
    },
    isDisabled() {
      return true;
    },
  },
  watch: {
    selectedBsdtCode() {
      this.getBsdtDetail();
    },
  },
  mounted() {
    // 验证码初始化
    this.getNewVerCode();
    // console.log('user--->>>', this.user);
    this.qszmFormData.loginName = this.user.loginName;
    if (this.disabled) {
      this.qszmFormData.qlrmc = this.user.name;
      if (this.user.userCategory === 'NORMAL') {
        this.qszmFormData.zjzl = this.user.zjzl ? this.user.zjzl : '1';
        this.qszmFormData.zjh = this.user.zjh;
      } else if (this.user.userCategory === 'KFS' || this.user.userCategory === 'FINANCIAL') {
        this.qszmFormData.zjzl = this.user.qyzjzl || '7'; // 开发商企业证件类型默认营业执照
        this.qszmFormData.zjh = this.this.user.qyzjh;
      }
    }
    console.log(this.user, 'user');
    // if (!JSON.parse(localStorage.getItem(user'chooseDeviceInfo'))) {
    //   //缓存中没有值的时候执行接口获取之前选择的设备
    //   this.getDevice();
    // } else {
    //   //有值直接获取存储中的值
    //   this.chooseDeviceInfo = JSON.parse(localStorage.getItem('chooseDeviceInfo'));
    // }
    this.getBsdtDetail();
  },
  methods: {
    // 新增验证码相关逻辑 start
    getNewVerCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    // 新增验证码相关逻辑 end
    // getDevice() {
    //   getDeviceList({ deviceUser: this.user.id }).then((res) => {
    //     if (res.success && res.data) {
    //       res.data.forEach((item, index) => {
    //         if (item.defaultStatus === 0) {
    //           this.$set(this.chooseDeviceInfo, 'deviceBh', res.data[index].deviceBh);
    //           this.$set(this.chooseDeviceInfo, 'deviceIp', res.data[index].deviceIp);
    //           this.$set(this.chooseDeviceInfo, 'deviceId', res.data[index].id);
    //           localStorage.setItem('chooseDeviceInfo', JSON.stringify(this.chooseDeviceInfo));
    //         }
    //       });
    //     }
    //   });
    // },
    getListData(val) {
      this.qszmFormData.nCurrent = val;
      this.submit();
    },
    // 验证表单  点击提交事件
    submit() {
      this.resultFlag = false;
      if (!this.selectedBsdtCode) {
        this.$message.warning('请选择办事大厅!');
        return;
      }
      // this.loading = true;
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let params3 = {
            loginName: localDataUser.get().loginName,
            xzqh: getCookie('selectedAreaCode'),
            name: this.qszmFormData.qlrmc,
            zjh: this.qszmFormData.zjh,
            organizationName: this.selectedOrganizationsTitle,
            userName: localDataUser.get().username,
            checkCertifyTypeEnum: 'BDCFCCX',
            visible: '1',
            officeCode: this.selectedBsdtCode,
            sqrqydm: this.ipData.sqrqydm,
            sqrip: this.ipData.sqrip,
            sqrszd: this.ipData.sqrszd,
          };
          checkCertifyInspect(params3)
            .then((res) => {
              if (res.data && res.success) {
                let data = res.data.data ? JSON.parse(res.data.data) : '';
                this.cxjgData = res.data.data;
                let dataFat = [];
                data.data.records.forEach((item) => {
                  if (item.status == '查询到结果') {
                    this.$set(item, 'cxbh', res.data.receiveSeqNo);
                    dataFat.push(item);
                  }
                });
                this.resultNumber.receiveSeqNo = res.data.receiveSeqNo;
                this.resultNumber.bdcxxList = dataFat;
                this.totalNum = this.resultNumber.bdcxxList.length;
                this.resultFlag = true;
                this.index++;
              } else {
                this.$message.error('暂无房产');
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            })
            .finally(() => {
              this.refreshCode();
            });
        }
      });
    },
    // // 房产表格弹窗
    // printQszm (params) {
    //   printQszm({ cxbh: params }).then((res) => {
    //     if (res.data && res.success) {
    //       // this.printQszmUrl = res.data;
    //       window.open(res.data)
    //       // this.$nextTick(() => {
    //       //   this.dialogConfig.visible = true;
    //       // });
    //     }
    //   });
    // },
    // 获取办事大厅信息
    getBsdtDetail() {
      this.blwd = this.selectedOrganizationsTitle || '';
      // 获取办事大厅详情
      bsdtDetail({ officeCode: this.selectedBsdtCode }).then((res) => {
        console.log('办事大厅=', res);
        if (res.success && res.data) {
          this.wddh = res.data.dh;
          this.wddz = res.data.address;
        }
      });
    },
    //身份证信息读取 一体机
    // getIdCardInfo() {
    //   //判断是否已经选择一体机机器型号
    //   if (this.chooseDeviceInfo.deviceId) {
    //     let deviceBh = this.chooseDeviceInfo.deviceBh ? this.chooseDeviceInfo.deviceBh : this.chooseDeviceInfo.deviceId;
    //     //直接获取身份证
    //     getInfoByDeviceBh({ deviceBh: deviceBh }).then((res) => {
    //       if (res.success) {
    //         const result = res.data;
    //         if (!result) {
    //           this.$message.error('请确认选择机型编码是否正确,或信息失效');
    //           return false;
    //         }
    //         if (result.dataStatus === '0') {
    //           this.$message.error('信息失效');
    //           return false;
    //         }
    //         if (result.verifyResult === 0 || result.verifyResult === '0') {
    //           this.sqrData.qlrmc = result.name;
    //           this.sqrData.ywrmc = result.name;
    //           this.sqrData.zjzl = '1';
    //           this.sqrData.zjh = result.cardNo;
    //           this.sqrData.idImage = result.idImage;
    //           this.sqrData.faceImage = result.faceImage;
    //           if (result.mopsGmsfhmResult === '一致' && result.mopsXmResult === '一致') {
    //             this.sqrData.gayz_img = require('@iebdc/assets/images/gayz-success.png');
    //             this.sqrData.gayz = '实名认证成功';
    //           } else {
    //             this.sqrData.gayz_img = require('@iebdc/assets/images/gayz-fail.png');
    //             this.sqrData.gayz = '实名认证失败';
    //           }
    //           this.$refs.form.setData(this.sqrData);
    //         } else {
    //           this.$message.error('实名认证失败');
    //         }
    //       } else {
    //         this.$message.error(res.message);
    //       }
    //     });
    //   } else {
    //     this.$message.error('请前往用户中心选择一体机编号！');
    //   }
    // },
    //添加家庭关系
    // addRelationship() {
    //   this.dynamicValidateForm.familyMemberList.push({
    //     xm: '',
    //     zjzl: userInfo.zjzl,
    //     zjh: '',
    //     memberShip: '',
    //   });
    // },
    // deleteMsg(index) {
    //   this.dynamicValidateForm.familyMemberList.splice(index, 1);
    // },
    // 查询证书
    // qszmCheck() {
    //   // 处理数组中是值是对象时的格式
    //   const copyFamilyMemberList = [...this.userRzRegister.familyMemberList];
    //   delete this.userRzRegister.familyMemberList;
    //   for (let i = 0, len = copyFamilyMemberList.length; i < len; i++) {
    //     const obj = copyFamilyMemberList[i];
    //     for (const key in obj) {
    //       this.userRzRegister[`familyMemberList[${i}].${key}`] = obj[key];
    //     }
    //   }
    //   qszmCheck(this.userRzRegister).then((res) => {
    //     if (res && res.success) {
    //       this.$emit('next', 2);
    //     }
    //   });
    // }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/pages/iebdc/styles/public.scss';
@import '~@/pages/iebdc/styles/common-variables.scss';
.fill-information-container {
  // background-color: #ffffff;
  padding: 20px 140px;

  .lc-name {
    color: orange;
    margin-top: 20px;
  }

  .rz_img {
    margin-top: 10px;

    .rz_img_face {
      margin-left: 20px;
    }
  }
  .flex {
    justify-content: space-between;
  }
  .code {
    height: 40px;
    img {
      height: 100%;
    }
  }
  /*
  .main-box {
    .header {
      height: 42px;
      line-height: 42px;
      background-color: rgba(242, 246, 252, 1);
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: left;
      font-family: Microsoft Yahei;

      span {
        margin-left: 10px;
      }
    }

    .body {
      border: 1px solid #dcdcdc;
      padding: 30px 10px;
      margin-bottom: 40px;
    }

    .member-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
   */

  /deep/ .el-select {
    width: 100%;
  }
}
.custom-class {
  /deep/ .el-dialog {
    height: 70%;
    .el-dialog__body {
      max-height: 85%;
    }
  }
}
</style>
